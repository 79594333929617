import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'



class GameInfoIndex extends React.Component {

    render() {
        const gamed = this.props.data.game;

        function unescapeHtml(safe) {
            return safe
                .replace(/&amp;/g, "&")
                .replace(/&mdash;/g, "-")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&#9;/g, "")
                .replace(/&hellip;/g, "")
                .replace(/&#10;/g, "\n")
                .replace(/&#039;/g, "'");
        }

        let statusColor = "";
        switch (gamed.pp.status) {
            case "Available":
                statusColor = "bg-green";
                break;
            case "Upon Request":
                statusColor = "bg-blue";
                break;
            case "With Limited Availability":
                statusColor = "bg-yellow";
                break;
            case "Not Available":
                statusColor = "bg-red";
                break;
            case "Coming Soon":
                statusColor = "bg-purple";
                break;
        }

        return (
            <Layout>
                <Helmet>
                    <title>Parlour Party</title>
                    <meta name="description" content="Parlour Party" />
                </Helmet>

                
                <div id="main" className="alt">

                    
                    <section id="one">
                        <div className="inner">
                            <header className="major">
                                <h1>{gamed.pp.name}</h1>
                            </header>
                            <span className="image main" id="banner" style={{padding: "0px"}}>
                                <img src={`${gamed.bgg.image}`} style={{height: "60vh",width: "auto", margin: "0 auto"}} alt="" className="inner" />
                            </span>
                            <hr className="minor"></hr>
                            <div className="grid-wrapper">
                                <div className="col-3">
                                    {/* <h3>Duration</h3> */}
                                    <ul className="actions">
                                        <li><i className="icon alt fa-clock-o"></i></li>
                                        <li><span>{gamed.bgg.playingTime} mins</span></li>
                                    </ul>
                                </div>
                                <div className="col-3">
                                    {/* <h3>Players</h3> */}
                                    <ul className="actions">
                                        <li><i className="icon alt fa-users"></i></li>
                                        <li><span>{gamed.pp.bestPlayerCount} people</span></li>
                                    </ul>
                                </div>
                                <div className="col-3">
                                    {/* <h3>Players</h3> */}
                                    <ul className="actions">
                                        <li><i className={`icon alt fa-book ${statusColor}`}></i></li>
                                        <li><span>{gamed.pp.count} Copies {gamed.pp.status}</span></li>
                                    </ul>
                                </div>
                                <div className="col-3 ">
                                    {/* <h3>Players</h3> */}
                                    <ul className="actions">
                                        <li><i className="icon alt fa-link"></i></li>
                                        <li><a href={`${gamed.pp.bggurl}`}>BoardGameGeek</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid-wrapper">
                                <div className="col-4 box">
                                    <h3>Benefits Of This Game</h3>
                                    <p>{gamed.pp.benefitsOfThisGame}</p>
                                </div>
                                <div className="col-4 box">
                                    <h3>Who Will Like This Game?</h3>
                                    <p><ul>
                                    {
                                        gamed.pp.whoIsThisGameFor.map(who => {
                                            return (
                                                <li>{who}</li>
                                            )
                                        })
                                    }
                                    </ul></p>
                                </div>
                                <div className="col-4 box">
                                    <h3>Gamer Level</h3>
                                    <p><ul>
                                    {
                                        gamed.pp.gamerLevel.map(level => {
                                            return (
                                                <li>{level}</li>
                                            )
                                        })
                                    }
                                    </ul></p>
                                </div>
                            </div>
                            <h2>Description</h2>
                            <p>
                                {unescapeHtml(gamed.bgg.description)}
                            </p>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default GameInfoIndex


export const query = graphql`
    query GameDetails($gameId: String!) {
        game(id: { eq: $gameId }) {
            id
            bgg {
                image
                minPlayers
                maxPlayers
                playingTime
                description
            }
            pp {
                name
                bggurl
                bggid
                count
                bestPlayerCount
                whoIsThisGameFor
                gamerLevel
                benefitsOfThisGame
                status
                recommendedVenueType
            }
        }
    }
`